import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { Tooltip } from 'react-tooltip';
import { HashNavLink } from './HashNavLink';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const CustomNavbar = (props) => {
    const { t, i18n } = useTranslation();
    const { mClass, nClass, cClass, slogo, hbtnClass } = props;
    const navigate = useNavigate();

    const toggleLanguage = () => {
        const newLanguage = i18n.language === 'en' ? 'bg' : 'en';
        i18n.changeLanguage(newLanguage);

        const pathSegments = window.location.pathname.split('/');
        pathSegments[1] = newLanguage;
        const newPath = pathSegments.join('/') + window.location.hash;
        navigate(newPath);
    };

    const attachLangToPath = (path) => {
    return `/${i18n.language}${path}`;
};

    return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                    <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                        <div className={`container ${cClass}`}>
                            <Link className={`navbar-brand ${slogo}`} to={attachLangToPath("")}>
                                <img src={require("../img/abies_logo_500-300px_w-01.png")} alt="" />
                                <img src={require("../img/abies_logo_500-300px-01.png")} alt="logo" />
                            </Link>
                            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ float: 'right' }}>
                                <ul className={`navbar-nav menu ms-auto ${nClass}`}>
                                    <li className="nav-item"><HashNavLink title="Home" className="nav-link" to={attachLangToPath("/#home")}>{t("home.title")}</HashNavLink></li>
                                    <li className="nav-item"><NavLink title="About" className="nav-link" to={attachLangToPath("/About")} style={{whiteSpace: "nowrap"}}>{t("about.aboutUs")}</NavLink></li>
                                    <li className="nav-item"><HashNavLink title="ForTeachers" className="nav-link" target-id="for_teachers" to={attachLangToPath("/#for_teachers")}>{t("forTeachers.title")}</HashNavLink></li>
                                    <li className="nav-item"><HashNavLink title="ForSchools" className="nav-link" target-id="for_schools" to={attachLangToPath("/#for_schools")}>{t("forSchools.title")}</HashNavLink></li>
                                    <li className="nav-item"><HashNavLink title="ForStudents" className="nav-link" target-id="for_students" to={attachLangToPath("/#for_students")}>{t("forStudents.title")}</HashNavLink></li>
                                    <li className="nav-item" data-tooltip-id="coming-soon" data-tooltip-content="Coming soon!"><NavLink title="Blog" className="nav-link disabled" to={attachLangToPath("/blog")}>{t("common.blog")}</NavLink></li>
                                    <li className="nav-item"><NavLink title="Contact" className="nav-link" to={attachLangToPath("/Contact")} style={{whiteSpace: "nowrap"}}>{t("common.contact")}</NavLink></li>
                                    <li className='nav-item' style={{whiteSpace: "nowrap"}}>
                                        <span 
                                            className="nav-link" 
                                            onClick={toggleLanguage}
                                        >
                                             {i18n.language === 'en' ? "BG" : "EN"} <FontAwesomeIcon icon={faGlobe} />
                                        </span>
                                    </li>
                                </ul>
                                <a className={`btn_get btn_hover ${hbtnClass}`} style={{whiteSpace: "nowrap"}} href={attachLangToPath("/signup")}>{t("signUp.title")}</a>
                            </div>
                        </div>
                    </nav>
                </header>
                <Tooltip id="coming-soon" />
            </Sticky>
    );
};

export default CustomNavbar;
