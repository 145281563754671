export const translationBG = {
  common: {
    english: "Английски",
    bulgarian: "Български",
    en: "АН",
    bg: "БГ",
    selectLanguage: "Изберете език",
    preferredLanguage: "Предпочитан език",
    moreInfo: "Повече информация",
    subscribe: "Абонирайте се",
    subscribeForNewsletter: "Абонирайте се за нашия бюлетин и научете първи за новите ни функционалности!",
    yourName: "Вашето име",
    yourEmail: "Вашият имейл",
    yourPosition: "Вашата позиция",
    yourEducationalInstitution: "Вашето учебно заведение (училище, университет и др.)",
    email: "Имейл",
    copyrigth: "© ReachUp Ltd. 2024 Всички права запазени.",
    contact: "За контакт",
    contactUs: "Свържете се с нас",
    contactUsDescription: "Имате въпрос? Искате да видите нашия продукт в действие? Изпратете ни съобщение и ние ще се свържем с вас!",
    contactInfo: "Информация за контакт",
    leaveMessage: "Оставете съобщение",
    subject: "Тема",
    blog: "Блог",
    enterYourMessage: "Въведете съобщението си . . .",
    sendMessage: "Изпращане на съобщението",
    requestAnInvitation: "Заявете покана",
    messageSuccessfullySent: "Вашето съобщение е успешно изпратено!",
    oopsError: "Грешка! Има нещо нередно. Моля, опитайте отново",
    taskManagementPortal: "Портал за управление на задачи",
    abiesCapitalized: "ABIES",
    abiesPronounce: "/ˈæ.biˌiz/",
    noun: "съществително",
    etymology: "Етимология: ",
  },
  about:{
    aboutUs: "За нас",
    foundationalStory: "Компанията е основана през 2013 в София, от група млади специалисти в областта на информационните технологии. Целта, която ни води и до днес, е да споделяме знания и подобяваме качеството на образователните услуги в ИТ и да ги направим по-достъпни в България и по света. Води ни твърдото убеждение, че уменията за програмиране са една от ключовите компетентности, необходими за бъдещ успех във всяка професия.",
    companyOriginStory: "Всичко започна чрез организиране на университетски курсове и обучения във фирми по различни теми - програмиране с Java, iOS, C, C++, също така Софтуерна Сигурност, Софтуерни архитектури, Дизайн, Гъвкави методологии, Разработка на изисквания и други.",
    trainingDetails: "Всяко от нашите обучения е персонализирано и разработено според нуждите на обучителната групата. Оптимизираме както материала, така и методите за преподаване, за постигане на високи резултати като предаваме знания, умения и дори нагласи. За да постигане оптималния си потенциал, всеки обучаем се нуждае от персонализиран подход и много внимание. Ние предоставяме интерактивни сесии, уникална комбинация от лекции, упражнения и дискусии, казуси и симулации. Много често се налага да приспособяваме съдържанието или методите на предаване по време на самото обучение и да прилагаме множество техники, за да достъгнем до всеки участник в групата.",
    automationToolsDevelopment: "Разработихме многообразие от инструменти за автоматизация и оптимизация на учебния процес, сърцето на платформата Abies, които първоначално използвахме за нашите курсове. Това намали административия товар за преподавателя, спестява време и енергия от еднообразни задачи. А когато видяхме ползите и колко по-добре реагираха студентите на практичния, интерактивен подход, разбрахме колко ценно нещо сме създали. През 2023 година решихме да го превърнем в продукт, и така се роди Abies.io!",
    abiesCapitalizedDefinition: "1. с главна буква: род (биологична таксономия), семейство Pinaceae; вечнозелени дървета на умерени райони на северното полукълбо, ЕЛА",
    abiesDefinition: "2. abies: всяко дърво от рода Abies; ела",
    abiesEtymologyRoots: "Два възможни корена:",
    abiesLatinOrigin: '(латински) "отивам много далеч"',
    abiesGreekOrigin: '(гръцки) "живее дълго / устойчив"',
  },
  signUp:{
    title: "Регистрация за Abies бета",
    heroTitle: "Регистрирайте се за Abies.io",
    heroDescription: "Помогнете ни да подобрим Abies.io и ни кажете какво би го направило най-полезен за вашите нужди!",
    secondaryTitle: "Abies.io е в частна бета версия, безплатна, само с покани.",
    secondaryDescription: "Ако искате да го изпробвате, моля, въведете данните си във формуляра по-долу и ние ще ви изпратим имейл с инструкции за нашия портал. Вашите отзиви ще бъдат високо оценени. Ще ни помогнете да насочим продукта в най-добрата посока, а като благодарност ще получите и безплатни кредити за публичното издание."
  },
  home:{
    title: "Начало",
    teach: "Преподавай",
    programming: "програмиране",
    by: "чрез",
    practice: "интерактивни практически упражнения",
    description: "Подобрява ангажираността на учениците, автоматиизра оценяването на практически задания, намалява административния товар",
    howItWorks: "Как работи?",
    abiesIntro: "Гледай Демо",
    taskPortal: "Портал Задачи",
  },
  forTeachers: {
    title: "За учители",
    subtitlePart1: "Abies позволява на учителите да фокусират ценното си време върху най-важния аспект на работата си:",
    subtitlePart2: "Да обучават своите ученици!",
    autoGradeAssignments: "Автоматично оценяване",
    autoGradeAssignmentsDescription: "Незабавна обратна връзка, цялостен анализ и многомерна оценка. Проверка на функционалността, производителността и качеството на кода, стил на писане, четимост, организация и други",
    startTeachingInstantly: "Започнете бързо и безпроблемно",
    startTeachingInstantlyDescription: "Не се изисква инсталация или поддръжка на софтуера. Избягване на проблеми с конфигурацията и съвместимостта - повече няма да ви се налага да казвате 'Това работи на моя компютър'! ",
    shareAssignments: "Персонализация и гъвкавост на материала",
    shareAssignmentsDescription: "В нашата база данни можете да намерите вече изпробвани и доказани задачи.  Изберете най-подходящата за всеки случай - ако някой ученик има нужда от по-сложна или по-проста задача, за да навакса с темпото на класа. ",
  },
  teachingBasicFeatures: {
    title: "Вашият процес на преподаване в нашата платформа",
    description: "Интегрира се бързо и лесно във вашата инфраструктура и процес на рбаота - информационна система (LMS), електронен дневник, SSO",
  },
  forSchools: {
    title: "За училища",
    subtitle: "Безпроблемно преминаване към обслужване на повече ученици, задания, курсове",
    alwaysOn: {
      title: "Винаги на разположение",
      automatic: {
        title: "Автоматизация",
        description: "Abies.io тества всички подадени от учениците материали с всички поставени критерии. Учителят може да прегледа и даде допълнителна обратна взъзка, но това не е задължително",
      },
      available: {
        title: "На разположение 24/7 365",
        description: "Системата е винаги на разположение. Позволява на учениците да се упражняват в удобно за тях време и локация, както и да се организират географски независими курсове",
      },
      support: {
        title: "Поддръжка",
        description: "Нашият екип осигурява поддръжка на следващия работен ден за на всеки билет",
      }
    },
    pricing: {
      title: "Ценообразуване",
      affordable: {
        title: "Достъпни цени",
        description: "С цена, започваща само от няколко долара на месец, нашата цел е да осигурим възможност за всички студенти да бъдат записани! Инструментите за автоматична оценка на Abies са с гъвкаво и съобразено ценообразуване при растеж. Това позволява провеждането на масови курсове на разумна цена.",
      },
      pricing: {
        title: "Ценообразуване",
        descriptionPart1: "Цените започват от 5 EUR / за ученик / на месец, като можете да получите отстъпка за по-голям брой ученици.",
        descriptionPart2: "Освен това можете да се присъедините към нашата частна бета версия напълно безплатно и дори да спечелите кредити за публичната версия в знак на благодарност за ценната ви обратна връзка."
      },
    },
    fair: {
      title: "Цялостно",
      consistent: {
        title: "Последователно",
        description: "Abies.io извършва автоматично тестване на всички ученически решения. Всички представени решения се третират еднакво и резултатите от оценяването са последователни. Показателите за ефективност се отчитат с доверителен интервал, което позволява на учителите ръчно да проверяват и анализират решенията 'на ръба' на границите."
      },
      hollistic: {
        title: "Холистично",
        description: "Цялостен подход към оценяването - винаги тества всички тестови случаи, измерва качеството на кода, структурата на кода и производителността по всички зададени критерии"
      },
      feedback: {
        title: "Доволни студенти = доволен преподавател",
        description: "Подобрение на ангажираността и удовлетвореността на учениците, на вниманието и участието им при предаване на домашни! "
      }
    },
    extensive: {
      title: "Гъвкавост",
      variety: {
        title: "Персонализация",
        description: "Платформата предлага голямо разнообразие на задачи - от начално до професионално ниво. изберете по-лесни задачи на учениците, които се нуждаят от допълнителна практика, и по-предизвикателни на тези, които иначе биха се отегчили. Постепенно увеличавайте сложността на упражненията, за да постигнете максимлния учебен потенциал за всеки ученик, като създадете индивидуален учебен план."
      },
      tiered: {
        title: "Многостепенни",
        description: "Abies.io е повече от програма за оценяване на код - можете да изпробвате нашите разширени функции, включително:",
        bulletA: "Задачи, базирани на Git, които преподават контрол на версиите и автоматизирано тестване",
        bulletB: "Задачи за кодиране в група, които преподават реални процеси на разработване",
        bulletC: "Нашият модул за уеб сигурност, който преподава основни концепции за WebSec чрез практически примери",
      },
      collaborative: {
        title: "Сътрудничество",
        description: "Споделяйте и си сътрудничете по заданията с учители от цял свят, за да намерите най-подходящото за вашите ученици и обучения."
      },
      integration: {
        title: "Програмен интерфейс (API)",
        description: "Използвате персонализирана платформа за своите курсове и обучения? Все още можем да ви помогнем: Abies.io е проектиран по парадигмата API-first. Всяка функционалност, която можете да видите, можете да използвате програмно."
      }
    },
    batteriesIncluded: {
      title: "Батериите са включени!",
      integratesWithLMS: {
        title: "Интеграция с LMS",
        description: "Abies.io поддържа протокола LTI, който позволява интеграция с Moodle, Canvas и много други LMS. Поддържаме и автоматиизран достъп чрез access token urls. Можете да задавате проблеми на своите ученици в Google Classroom или дори Slack, Teams и др."
      },
      supportsMultipleLanguages: {
        title: "Поддържа множество езици",
        description: "Всяка задача може да бъде решавана на множество езици за програмиране! В момента поддържаме Java, C, C++, C#, JavaScript, Python и други. Екипът ни непрекъснато работи за увеличаване на този списък, така че не се колебайте да се свържете с нас, ако имате нужда от друг език!"
      },
      flexibleAuthentication: {
        title: "Удостоверяване на идентичност",
        description: "Знаем, че регистрацията в още едно приложение е прекалено много. Поддържаме интеграция на SSO с най-популярните доставчици в готов вид и можем да съдействаме за интегрирането на други доставчици на SSO!"
      },
    },
    hero: {
      title: "Анализ на представянето на учениците, тяхната ангажираност и проследяване на напредъка им",
      description: "Проследявайте и изобразявайте в различна форма данни за представянето на учениците по заданията, по теми или области на знания/умения. Адаптирайте следващите задания и материала въз основа на метрики - на какво да се наблегне и какво е усвоено и до каква степен. Генерирайте отчети в табличен вид или графики, които проследяват представянето на учениците във времето и областите на познание. Можете дори да измервате ефективността на заданията - тествайте тестовете!",
      button: "Научете повече"
    }
  },
  forStudents: {
    title: "За студенти",
    subtitle: "Учете се да програмирате навсякъде и по всяко време",
    description: "Интерактивни, практически обучения. Получете незабавна обратна връзка за функционалността, качеството на кода, структурата, производителността и много други",
    instant: {
      title: "Незабавно",
      description: "Получавате обратна връзка за минути и продължавате да работите по проблема, докато не го разрешите. Не чакайте повече, за да разберете дали решението работи и до колко - пишете и изпълнявайте своите задания в Abies"
    },
    fair: {
      title: "Цялостно",
      description: "Abies оптимиизра и унифицира процеса на оценяване за целия клас! Получавате шаблон като рамка, ясни изисквания за инфраструктура и конфигурация - работи еднакво от всякъде! Получавате оценка за множество аспекти на решението - резултати, производителност, структура и архитектура и др. Прегледайте препоръките от линерите и инструментите за качество на кода. Получавайте обратна връзка директно за вашия код, като се позовавате на добри практики от индустрията, и подобрявайте стила на писане на код с всяко задание."
    },
    tailored: {
      title: "Персонализирано",
      descriptionPart1: "Получавате най-подходящото упражнение за индивидуалното си ниво и опит - вдигайте нивото на сложност постепенно, като решавате следващото предизвикателство. Постигнете оптимални резулати и изпълнете потенциала си - можете да наваксате, можете да продължите напред пред групата!",
      descriptionPart2: "Сложноста се вдигa в множество аспекти - компоненти на езика, алгоритъм, производителност, стил, четимост и др."
    },
    noHassle: {
      title: "Безпроблемно",
      description: "Сведете до минимум проблемите с инсталацията, конфигурацията и настройки. Не се изисква предварителна настройка и решнието може да се предаде от всяко устройство с интернет и модерен браузър: Mac, PC, Android, iOS, Chromebook - ние поддържаме всички!"
    }
  },
};
