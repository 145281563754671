export const translationEN = {
  common: {
    english: "English",
    bulgarian: "Bulgarian",
    en: "EN",
    bg: "BG",
    selectLanguage: "Select language",
    preferredLanguage: "Preferred language",
    moreInfo: "More info",
    subscribe: "Subscribe",
    subscribeForNewsletter: "Subscribe for our newsletter and learn about our new features first!",
    yourName: "Your name",
    yourEmail: "Your email",
    yourPosition: "Your position",
    yourEducationalInstitution: "Your educational institution (school, university, etc.)",
    email: "Email",
    copyrigth: "© ReachUp Ltd. 2024 All rights reserved.",
    contact: "Contact",
    contactUs: "Contact us",
    contactUsDescription: "Have a question? Want to see our product in action? Drop us a message and we'll get in touch!",
    contactInfo: "Contact info",
    leaveMessage: "Leave a message",
    subject: "Subject",
    blog: "Blog",
    enterYourMessage: "Enter your message . . .",
    sendMessage: "Send message",
    requestAnInvitation: "Request an invitation",
    messageSuccessfullySent: "Your message succesfully sent!",
    oopsError: "Opps! There is something wrong. Please try again",
    taskManagementPortal: "Task Management Portal",
    abiesCapitalized: "ABIES",
    abiesPronounce: "/ˈæ.biˌiz/",
    noun: "noun",
    etymology: "Etymology",
  },
  about: {
    aboutUs: "About us",
    foundationalStory: "The company was founded in 2013, in Sofia, Bulgaria, by a group of young and excited IT specialists, who wanted to share their experience. Together we formed a comunity aiming to improve the quality of IT education in Bulgaria and make it more accessible. We are led by a strong believe that programming skills are one of the key competencies necessary for future success in any profession.", 
    companyOriginStory: "It all started by organizing university courses and in-company workshops on various subjects - Programing with Java, iOS, C, C++ to name a few, also Software Security, Software Architecture & Design, Requirements Engineering in Agile world, and others.",
    trainingDetails: "We specially customized each training for the needs of the group and optimized for achieving high results in transfering knowledge, skills and even attitude. It was clear that for maximum results each trainee needs a personalized approach and much attention. We delivered interactive sessions, unique bland of lectures, excersises and discussions, case studies and simulations. Very often we needed to adjsut the content or the methods of delivering it, during the training itself.",
    automationToolsDevelopment: "We built various automation tools, the core of Abies platform, we initially used for our courses. It reduced the administrative load on the trainer, saved time and energy from mundaine tasks. Then we saw the benefits and how much better our training and students responded to the practical, interactive approach. In 2023, we decided to turn it into a product, and thus Abies.io was born!",
    abiesCapitalizedDefinition: "1. capitalized : a genus (biological taxonomy), family Pinaceae; evergreen trees of temperate regions of the northern hemisphere that comprise the true firs",
    abiesDefinition: "2. abies : any tree of the genus Abies; fir",
    abiesEtymologyRoots: "Two possible roots:",
    abiesLatinOrigin: '(Latin) "going far away" ',
    abiesGreekOrigin: '(Greek) "lives long / enduring"',
  },
  signUp:{
    title: "Join our private beta",
    heroTitle: "Sign Up for Abies.io",
    heroDescription: "Help us improve Abies.io product and tell us what would make it most useful to your needs!",
    secondaryTitle: "Abies.io is in Private Beta release, free of charge, invite-only.",
    secondaryDescription: "If you are excited to try it out, please give us your details in the form bellow and we will send you an email with instructions to our portal. Your feedback will be highly appreciated. You get to help us steer the product in the best direction and as a Thank you, you will get free credits for the public release as well."
  },
  home:{
    title: "Home",
    teach: "Teach",
    programming: "programming",
    by: "by",
    practice: "practice",
    description: "Improve student engagement, automate grading, reduce administrative load and much more",
    howItWorks: "How it works",
    abiesIntro: "Abies Intro",
    taskPortal: "Task Portal",
  },
  forTeachers: {
    title: "For Teachers",
    subtitlePart1: "Let teachers focus on teaching",
    subtitlePart2: "Abies.io will handle the rest",
    autoGradeAssignments: "Auto grade assignments",
    autoGradeAssignmentsDescription: "Generate instant feedback on student submissions. Track and measure code functionality, performance and quality.",
    startTeachingInstantly: "Start teaching instantly",
    startTeachingInstantlyDescription: "No installation or software maintenance required. Avoid configuration and compatibility issues - no more 'It works on my computer'! Students can use from any device (tablet, phone, PC).",
    shareAssignments: "Share assignments",
    shareAssignmentsDescription: "Choose from our assignment database, or browse community assignments to find the best match in difficulty and domain. Create new assignments, and share them with the community.",
  },
  teachingBasicFeatures: {
    title: "Your teaching process, in our platform",
    description: "Integrate seamlessly with your LMS - Moodle, Canvas and Google Classroom are all supported.",
  },
  forSchools: {
    title: "For Schools",
    subtitle: "Seamlessly go from deploying to scaling.",
    alwaysOn: {
      title: "Always-on",
      automatic: {
        title: "Automatic",
        description: "Abies.io tests all student submissions against the provided tests. No manual interaction is required.",
      },
      available: {
        title: "Available 24/7 365",
        description: "The system is always available, allowing students with time constraints to practice, as well as enabling geographically independent and global courses.",
      },
      support: {
        title: "Support",
        description: "Our team provides next business day support - any ticket will be reacted to within this SLA.",
      }
    },
    pricing: {
      title: "Pricing",
      affordable: {
        title: "Affordable",
        description: "The Abies.io code grader was designed from the ground up to provide a solution with flexible and considerate pricing enabling massive courses. With a price tag starting at just a few dollars a month, our goal is to enable you to enroll your entire student body.",
      },
      pricing: {
        title: "Pricing",
        descriptionPart1: "Pricing starts at 5 EUR / student / month, and you can get a discount for a larger number of students.",
        descriptionPart2: "Additionally, you can join our Private Beta completely free of charge and even win credits for the Public release as an appreciation of your valuable feedback."
      },
    },
    fair: {
      title: "Fair",
      consistent: {
        title: "Consistent",
        description: "Abies.io performs automatic testing on all student solutions. All submissions are treated equally and the grading results are consistent. Performance metrics are reported with a confidence interval, allowing teachers to manually inspect and analyze solutions 'on the brink' of the limits."
      },
      hollistic: {
        title: "Hollistic",
        description: "Auto-grading offers a holistic approach to grading - always test all test cases, measure code quality, code structure and performance. All of this data is presented to the teacher and (optionally) to the student."
      },
      feedback: {
        title: "Feedback",
        description: "Improve student engagement and satisfaction, attention and participation. Students get instant feedback on their solution can continue working on the problem without waiting for teacher feedback. No time wasted, no context switching. Teachers can still provide targeted comments and suggestions to help students improve their code."
      }
    },
    extensive: {
      title: "Extensive",
      variety: {
        title: "Variety",
        description: "Adjust the level of exercise for each student: Abies.io has multiple task levels. You can assign easier tasks to students than need the extra practice and assign extra credit tasks to those that would otherwise get bored. Gradually increase the exercises complexity to get the maximum learning potential for each student by creating an individual learning plan."
      },
      tiered: {
        title: "Tiered",
        description: "Abies.io is more than a code grader - you can try our advanced features, including:",
        bulletA: "Git-based tasks that teach Version Control and Automated Testing",
        bulletB: "Competitive and cooperative group coding assignments that teach real-world dev processes",
        bulletC: "Our Web Security module that teaches fundamental WebSec concepts through practical examples",
      },
      collaborative: {
        title: "Collaborative",
        description: "Find the best exercises from our library of assignments/tasks. Share and collaborate on assignments with teachers all around the world to find the best fit for your students/trainings."
      },
      integration: {
        title: "Integration",
        description: "Using a custom platform for your courses and trainings? We can still help: Abies.io is designed using the API-first paradigm. Any functionality that you can see, you can consume programmatically."
      }
    },
    batteriesIncluded: {
      title: "Batteries included!",
      integratesWithLMS: {
        title: "Integrates with LMS",
        description: "Abies.io supports the LTI protocol, which enables integration with Moodle, Canvas and many other LMS. But that's not all - Google Classroom is supported as well!"
      },
      supportsMultipleLanguages: {
        title: "Supports multiple languages",
        description: "Abies.io is built from the ground up to be extensible. We currently support the Java, JavaScript, C, C++ and Bash programming languages. Our team is constantly working to increase that list, so feel free to get in touch if you need another language!"
      },
      flexibleAuthentication: {
        title: "Flexible Authentication",
        description: "We know that registering in one more app is too much to ask. We support SSO integration with the most popular providers out-of-the-box, and we can assist in integrating other SSO providers!"
      },
    },
    hero: {
      title: "Get valuable insights into the students performance, engagement, and track progress",
      description: "Track and graph student performance per asisgnment, topic or knowledge/skill area. Select the most appropriate assignments based on previous data. Collect metrics, set performance baselines and generate reports that track student performance over time and areas of expertise. You can even measure assignment efficiency - test the tests!",
      button: "Learn More"
    }
  },
  forStudents: {
    title: "For Students",
    subtitle: "Learn coding by practicing!",
    description: "Self-paced, hands-on learning experince. Get instant assesment on code quality, structure, functionality and performance.",
    instant: {
      title: "Instant",
      description: "Get instant feedback and continue working on a problem until you solve it. No more waiting to find out if it works - write & run tests from within Abies, or submit to find out your score."
    },
    fair: {
      title: "Fair",
      description: "Receive uniform grading on all aspects of your solution - results, performance, structure and architecture, etc. Review recommendations from linters and code quality tools. Receive feedback directly on your code referencing specific parts and practices while improving your work incrementally!"
    },
    tailored: {
      title: "Tailored",
      descriptionPart1: "Find the best exercise for your individual level and experience - speed up as you solve the next challenge to reach your full potential.",
      descriptionPart2: "You can catch up, you can go ahead. Work on tasks with appropriate level of complexity in various aspects - language components, algorithm, performance, programing paradigms, etc."
    },
    noHassle: {
      title: "No hassle",
      description: "Bring installation, configuration, setup issues to a minimum. Abies requires no preliminary setup and can be opened from any device with Internet and a modern browser: Mac, PC, Android, iOS, Chromebook - we support them all!"
    }
  },
};
