import React, { Component } from 'react';
import Player from '../components/Banner/videobtn';
import { withTranslation } from 'react-i18next';

class SignUp extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            position: '',
            institution: '',
            message: '',
            isPortalModalOpen: false
        }
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleIsPortalModalOpen = () => {
        this.setState(prevState => ({
            isPortalModalOpen: !prevState.isPortalModalOpen
        }));
    };

    render() {
        // const {name, email, subject, message, emailStatus} = this.state;
        const { t } = this.props;

        return (
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Office Address</h6>
                                <p className="f_400 f_size_15">Melbourne’s GPO 434 Bourke St. Dhaka VIC 3074, Australia</p>
                            </div> */}
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">{t("common.contactInfo")}</h6>
                                {/* <p className="f_400 f_size_15"><span className="f_400 t_color3">Phone:</span> <a href="tel:3024437488">(+096) 302 443 7488</a></p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Fax:</span> <a href="tel:3024437488">(+096) 204 353 6684</a></p> */}
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">{t("common.email")}:</span> <a href="mailto:info@abies.io">info@abies.io</a></p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-9">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_15">{t("signUp.secondaryTitle")}</h2>
                            <p> {t("signUp.secondaryDescription")}</p>
                            <div className='mb_15 action_btn d-flex align-items-center wow fadeInLeft'>
                                <div className="video_btn popup-youtube" onClick={this.handleIsPortalModalOpen}>
                                    <div className="icon" style={{  border: '1px solid gray'}}><i className="ti-control-play"></i></div>
                                    <span style={{color: 'black'}}>{t("common.taskManagementPortal")}</span>
                                </div>
                                <Player videoUrl={'https://www.youtube.com/watch?v=AZIUbcN-l_4'} open={this.state.isPortalModalOpen} toggleModal={this.handleIsPortalModalOpen} />
                            </div>
                            <form action="https://submit-form.com/zbPaizMB" className="contact_form_box" method="POST" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="name" name="name" placeholder={t("common.yourName")} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" name="email" id="email" placeholder={t("common.yourEmail")} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" id="position" name="position" placeholder={t("common.yourPosition")} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" id="institution" name="institution" placeholder={t("common.yourEducationalInstitution")} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea onChange={this.handleChange} name="message" id="message" cols="30" rows="10" placeholder={t("common.enterYourMessage")}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">{t("common.requestAnInvitation")}</button>
                            </form>
                            {/* {emailStatus ? emailStatus : null} */}
                            <div id="success">{t("common.messageSuccessfullySent")}</div>
                            <div id="error">{t("common.oopsError")}</div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default withTranslation()(SignUp);
